import DraggableScrollArea from "@/components/DraggableScrollArea";
import EntryThumbnail from "@/src/components/EntryThumbnail";
import clsx from "clsx";
import { debounce } from "lodash";
import useGTM from "@/hooks/useGTM";

const EntryCarousel = ({
  entries,
  slideClassName = undefined,
  slideSize = "normal", // normal, large, small
  mobileSlideSize = "large", // normal, large, small
  theme = "dark",
  aspectRatio = "square",
  disableRecipeActions = false,
  thumbnailOverlay = false,
  showSection = false,
  showChef = true,
  disableLink = false,
  className = "flex space-x-6 px-6 lg:px-12",
  headerComponent = null,
  mobileTitleSize = "base",
  railTitle = null,
  railId = null,
  showSummary = true,
}) => {
  const gtm = useGTM();

  // Slide className
  if (!slideClassName) {
    slideClassName = clsx("snap-center snap-always lg:snap-none", {
      "sm:!w-72 xl:!w-76": slideSize === "normal",
      "!w-[calc(100vw-7.5rem)] xs:!w-52": mobileSlideSize === "normal",
      "sm:!w-96 xl:!w-96": slideSize === "large",
      "!w-[calc(100vw-4.5rem)] xs:!w-60": mobileSlideSize === "large",
      "sm:!w-52 xl:!w-60": slideSize === "small",
      "!w-[calc(100vw-7.5rem)] xs:!w-44": mobileSlideSize === "small",
    });
  }

  const handleScroll = debounce(
    () => {
      gtm.pushEvent("rail_scrolled", {
        railTitle,
        railID: railId,
      });
    },
    1000,
    { leading: true, trailing: false }
  );

  return (
    <>
      <DraggableScrollArea onScroll={handleScroll}>
        <div className={clsx("EntryCarousel", className)}>
          {!!headerComponent && <div>{headerComponent}</div>}
          {entries.map((entry, index) => {
            const isFirst = index === 0;
            const isLast = index === entries.length - 1;

            return (
              <div key={entry.id} className={clsx(slideClassName)}>
                <EntryThumbnail
                  entry={entry}
                  disableRecipeActions={disableRecipeActions}
                  overlay={thumbnailOverlay}
                  showSection={showSection}
                  showChef={showChef}
                  disableLink={disableLink}
                  theme={theme}
                  priority={isFirst}
                  avoidCollisions={isLast}
                  saveRecipePopoverSide={"left"}
                  rail={{ title: railTitle, id: railId }}
                />
              </div>
            );
          })}
        </div>
      </DraggableScrollArea>
    </>
  );
};

export default EntryCarousel;
